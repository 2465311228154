export const itemCartReducer = (state = [], action) => {
    switch (action.type) {
        case 'ADD_ITEM_TO_CART':
            return state.concat(action.payload)
        case 'UPDATE_ITEM_CART':
            return action.payload
        case 'REMOVE_ITEM_FROM_CART':
            return action.payload
        case 'RESET_ITEM_CART':
            return []
        default:
            return state
    }
};