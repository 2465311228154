const initialState = {
    show: false,
    buttonText1: "",
    buttonText2: "",
    imageURL: "",
    themeColor: "",
    isConfirmation: false,
    callBackFunction: null
};

export const popupBoxReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_POPUP_BOX':
            return {
                show: action.payload.show,
                buttonText1: action.payload.buttonText1,
                buttonText2: action.payload.buttonText2,
                imageURL: action.payload.imageURL,
                themeColor: action.payload.themeColor,
                isConfirmation: action.payload.isConfirmation,
                callBackFunction: action.payload.callBackFunction
            };
        case 'RESET_POPUP_BOX':
            return initialState;
        default:
            return state;
    }
};