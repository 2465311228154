const initialState = {
    RemainingTime: 0
};

export const sessionTimeoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_REMAINING_TIME':
            return {
                RemainingTime: state.RemainingTime+1
            }
        case 'RESET_REMAINING_TIME':
            return {
                RemainingTime: 0
            }
        default:
            return state
    }
};