const initialState = {
    isRedeemed: false,
    redeemedAmount: 0,
    transactionReference: '',
    nexusReference: '',
    isFullNexusCheckout: false,
    isNexusRedeemedAmountCartValueComparisonAcknowledged: false
};

export const nexusRedemptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_NEXUS_REDEMPTION_DETAIL':
            return {
                isRedeemed: true,
                redeemedAmount: action.payload.custPointsRedeemed,
                transactionReference: action.payload.trxReferance,
                nexusReference: action.payload.trxRefID,
                isFullNexusCheckout: false,
                isNexusRedeemedAmountCartValueComparisonAcknowledged: false,
            };
        case 'UPDATE_FULL_CART_NEXUS_REDEMPTION':
            return {
                isRedeemed: state.isRedeemed,
                redeemedAmount: state.redeemedAmount,
                transactionReference: state.transactionReference,
                nexusReference: state.nexusReference,
                isFullNexusCheckout: action.payload.isFullNexusCheckout,
                isNexusRedeemedAmountCartValueComparisonAcknowledged: state.isNexusRedeemedAmountCartValueComparisonAcknowledged
            };
        case 'UPDATE_NEXUS_REDEEMED_AMOUNT_CART_VALUE_COMPARISON_ACKNOWLEDGE':
            return {
                isRedeemed: state.isRedeemed,
                redeemedAmount: state.redeemedAmount,
                transactionReference: state.transactionReference,
                nexusReference: state.nexusReference,
                isFullNexusCheckout: state.isFullNexusCheckout,
                isNexusRedeemedAmountCartValueComparisonAcknowledged: action.payload
            };
        case 'UPDATE_NEXUS_REDEEMED_AMOUNT':
            return {
                isRedeemed: state.isRedeemed,
                transactionReference: state.transactionReference,
                nexusReference: state.nexusReference,
                isFullNexusCheckout: state.isFullNexusCheckout,
                isNexusRedeemedAmountCartValueComparisonAcknowledged: state.isNexusRedeemedAmountCartValueComparisonAcknowledged,
                redeemedAmount: action.payload,
            };
        case 'SUCCESS_NEXUS_REDEMPTION':
            return {
                transactionReference: state.transactionReference,
                nexusReference: state.nexusReference,
                isFullNexusCheckout: state.isFullNexusCheckout,
                isNexusRedeemedAmountCartValueComparisonAcknowledged: state.isNexusRedeemedAmountCartValueComparisonAcknowledged,
                redeemedAmount: state.redeemedAmount,
                isRedeemed: true,
            };
        case 'RESET_NEXUS_REDEMPTION':
            return initialState;
        default:
            return state;
    }
};