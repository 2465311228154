const initialState = {
    redirectUrl: ""
};

export const userRedirectReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_USER_REDIRECT':
            return {
                redirectUrl: action.payload.redirectUrl
            };
        case 'RESET_USER_REDIRECT':
            return initialState;
        default:
            return state;
    }
};