import React, { Component } from 'react';
import './loadingScreen.scss';

import cube1 from '../../assets/img/loader/cube-1.jpg';
import cube2 from '../../assets/img/loader/cube-2.jpg';
import cube3 from '../../assets/img/loader/cube-3.jpg';

import cube4 from '../../assets/img/loader/cube-4.jpg';
import cube5 from '../../assets/img/loader/cube-5.jpg';
import cube6 from '../../assets/img/loader/cube-6.jpg';

import cube7 from '../../assets/img/loader/cube-7.jpg';
import cube8 from '../../assets/img/loader/cube-8.jpg';
import cube9 from '../../assets/img/loader/cube-9.jpg';

class LoadingScreen extends Component {
    render() {
        return (
            <div style={this.props.isVisible ? { display: '' } : { display: 'none' }} className="full-width-div">
                <div className="sk-cube-grid" style={{ height: 100, width: 100 }}>
                    <img src={cube1} className="sk-cube sk-cube1" alt="cube1" style={{ padding: '1px', borderRadius: '4px', backgroundColor: 'transparent' }} />
                    <img src={cube2} className="sk-cube sk-cube2" alt="cube2" style={{ padding: '1px', backgroundColor: 'transparent' }} />
                    <img src={cube3} className="sk-cube sk-cube3" alt="cube3" style={{ padding: '1px', borderRadius: '4px', backgroundColor: 'transparent' }} />

                    <img src={cube4} className="sk-cube sk-cube4" alt="cube4" style={{ padding: '1px', backgroundColor: 'transparent' }} />
                    <img src={cube5} className="sk-cube sk-cube5" alt="cube5" style={{ padding: '1px', backgroundColor: 'transparent' }} />
                    <img src={cube6} className="sk-cube sk-cube6" alt="cube6" style={{ padding: '1px', backgroundColor: 'transparent' }} />

                    <img src={cube7} className="sk-cube sk-cube7" alt="cube7" style={{ padding: '1px', borderRadius: '4px', backgroundColor: 'transparent' }} />
                    <img src={cube8} className="sk-cube sk-cube8" alt="cube8" style={{ padding: '1px', backgroundColor: 'transparent' }} />
                    <img src={cube9} className="sk-cube sk-cube9" alt="cube9" style={{ padding: '1px', borderRadius: '4px', backgroundColor: 'transparent' }} />
                </div>
            </div>
        )
    }
}

export default LoadingScreen