const initialState = {
    bannerList: [],
    bestSellersList: [],
    featuredProductsList: [],
    dealsProductList: [],
    departmentList: [],
    subDepartmentList: [],
    categoryList: [],
    suggestedItemsList: [],
    shopByCategoryList: [],
    paymentMethodsList: [],
    refundOptionsList: [],
    promotionCategoryList: [],
    systemConfiguration: [],
    fullfilmentMessageURL: ""
};

export const webDataCollectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_WEB_DATA_COLLECTION':
            return {
                bannerList: action.payload.bannerList,
                bestSellersList: action.payload.bestSellersList,
                featuredProductsList: action.payload.featuredProductsList,
                dealsProductList: action.payload.dealsProductsList,
                departmentList: action.payload.departmentList,
                subDepartmentList: action.payload.subDepartmentList,
                categoryList: action.payload.categoryList,
                suggestedItemsList: action.payload.suggestedItemsList,
                shopByCategoryList: action.payload.tileList,
                paymentMethodsList: action.payload.paymentMethodsList,
                refundOptionsList: action.payload.refundOptionsList,
                promotionCategoryList: action.payload.promotionCategoryList,
                systemConfiguration: action.payload.systemConfiguration,
                fullfilmentMessageURL: action.payload.fullfilmentMessageURL
            };
        case 'ADD_GUEST_WEB_DATA_COLLECTION':
            return {
                bannerList: state.bannerList,
                bestSellersList: state.bestSellersList,
                featuredProductsList: state.featuredProductsList,
                dealsProductList: state.dealsProductList,
                departmentList: state.departmentList,
                subDepartmentList: state.subDepartmentList,
                categoryList: state.categoryList,
                suggestedItemsList: state.suggestedItemsList,
                shopByCategoryList: state.shopByCategoryList,
                promotionCategoryList: state.promotionCategoryList,
                fullfilmentMessageURL: state.fullfilmentMessageURL,

                paymentMethodsList: action.payload.paymentMethodsList,
                refundOptionsList: action.payload.refundOptionsList,
                systemConfiguration: action.payload.systemConfiguration,
            };
        case 'REMOVE_FULLFILMENT_URL':
            return {
                bannerList: state.bannerList,
                bestSellersList: state.bestSellersList,
                featuredProductsList: state.featuredProductsList,
                dealsProductList: state.dealsProductList,
                departmentList: state.departmentList,
                subDepartmentList: state.subDepartmentList,
                categoryList: state.categoryList,
                suggestedItemsList: state.suggestedItemsList,
                shopByCategoryList: state.shopByCategoryList,
                promotionCategoryList: state.promotionCategoryList,
                paymentMethodsList: state.paymentMethodsList,
                refundOptionsList: state.refundOptionsList,
                systemConfiguration: state.systemConfiguration,

                fullfilmentMessageURL: "",
            };
        case 'RESET_WEB_DATA_COLLECTION':
            return initialState;
        default:
            return state;
    }
};
