import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import LoadingScreen from './components/loadingScreen/LoadingScreen';
import CacheBuster from './utility/cacheBuster';
import { IsPromotionSubDomain, PromotionEnvironment } from '../src/utility/environment_config';
import PDFViewer from './pages/pdfviever/PDFViewer';

/*-- Import required components --*/
const DefaultPage = React.lazy(() => import('./pages/defaultPage/DefaultPage'));
const LandingPage = React.lazy(() => import('./pages/landingPage/LandingPage'));
const CheckoutPage = React.lazy(() => import('./pages/checkoutPage/CheckoutPage'));
const VerifyCustomer = React.lazy(() => import("./components/verifyCustomer/VerifyCustomer"));
const PaymentGateway = React.lazy(() => import("./pages/PaymentGateway/NTB"));
const PaymentPageRedirectingHandler = React.lazy(() => import("./pages/PaymentGateway/PaymentPageRedirectingHandler"));
const AboutUs = React.lazy(() => import('./pages/aboutUs/AboutUs'));
const ThankYou = React.lazy(() => import("./pages/thankYouPage/ThankYou"));
const UtilityPaymentView = React.lazy(() => import('./pages/utilityPaymentPage/UtilityPayment'));
const TrackYourOrderView = React.lazy(() => import('./pages/trackYourOrderPage/TrackYourOrderView'));
const DeliveryInfo = React.lazy(() => import('./pages/deliveryInfoPage/DeliveryInfo'));
const FAQPage = React.lazy(() => import('./pages/faqPage/FaqPage'));
const PrivacyPolicy = React.lazy(() => import('./pages/privacyPolicyPage/PrivacyPolicyPage'));
const TermsAndConditions = React.lazy(() => import('./pages/termsAndConditionsPage/TermsAndConditionsPage'));
const StoreLocator = React.lazy(() => import('./pages/storeLocator/StoreLocator'));
const NexusTC = React.lazy(() => import('./pages/nexusT&CPage/NexusTandC'));
const MyMedOrderTracker = React.lazy(() => import('./pages/myMedOrderTrackerPage/MyMedOrderTracker'));
const ShowcaseExternal = React.lazy(() => import('./pages/showcase/ShowcaseExternal'));
const BrandPageExternal = React.lazy(() => import('./pages/brandPage/BrandPageExternal'));
const TurnPage = React.lazy(() => import('./pages/trunPage/TurnPage'));
const HelpWizard = React.lazy(() => import('./pages/helpWizard/HelpWizard'));
const EBill = React.lazy(() => import('./pages/eBill/EBill'));
const Feedback = React.lazy(() => import('./pages/feedback/Feedback'));
const DirectImportsPage = React.lazy(() => import('./pages/trunPage/DirectImportsPage'));
const CardPromo = React.lazy(() => import('./pages/cardPromo/CardPromoPage'));
const OnlineStoreExternal = React.lazy(() => import('./pages/onlineStore/OnlineStoreExternal'));
const ProductListing = React.lazy(() => import("./pages/productListingPage/ProductListingPage"));
const ProductListingExternal = React.lazy(() => import("./pages/productListingPage/ProductListingPageExternal"));
const PersonalizedPromotion = React.lazy(() => import('./pages/personalizedPromotion/PersonalizedPromotion'));
const ProductDetailExternal = React.lazy(() => import('./pages/ProductDetail/ProductDetailExternal'));
const ContactUsExternal = React.lazy(() => import('./pages/contactUsPage/ContactUsExternal'));
const PPCustomerPortal = React.lazy(() => import('./pages/personalizedPromotion/CustomerPortal'));
const PPCustomerPortalMsg = React.lazy(() => import('./pages/personalizedPromotion/PromotionMsg'));
const NexusRedemptioPage = React.lazy(() => import('./pages/nexusRedemption/NexusRedemptionPage'));
const ChurnWithLinkPromotion = React.lazy(() => import('./pages/personalizedPromotion/ChurnWithLink'));
const DonationExternal = React.lazy(() => import("./pages/DonationPaymentPage/DonationExternal"));
const ReceiptPage = React.lazy(() => import('./pages/receiptPage/receiptPage'));
const AMEXReturnPage = React.lazy(() => import('./pages/AMEXReturnPage/AMEXReturnPage'));
const GeoLocationExternal = React.lazy(() => import('./pages/GeoLocation/GeoLocationExternal'));
const AllPromotiomnsExternal = React.lazy(() => import('./pages/allPromotion/PromotionExternal'));
const BankPromotionExternal = React.lazy(() => import('./pages/allPromotion/bankPromotion/BankPromotionExternal'));
const ExclusivePromotionExternal = React.lazy(() => import('./pages/allPromotion/exclusivePromotion/ExclusivePromotionExternal'));
const Tabloid = React.lazy(() => import('./pages/tabloid/Tabloid'));
const UnSubriptionPage = React.lazy(() => import('./pages/tabloid/UnSubriptionPage'));
const SubriptionPage = React.lazy(() => import('./pages/tabloid/SubriptionPage'));
const WebExtension = React.lazy(() => import('./pages/webExtension/WebExtension'));
const MealCardExternal = React.lazy(() => import("./pages/MealCard/MealCardExternal"));
const PromotionPDF = React.lazy(() => import('./pages/promotionPDFPage/PromotionPDF'));
const MillionairePDF = React.lazy(() => import('./pages/MillionairePDFPage/MillionairePDF'));

class App extends Component {
  render() {
    let myLoading = () => (
      <LoadingScreen isVisible={true} />
    );

    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) { refreshCacheAndReload(); }
          if (!IsPromotionSubDomain) {
            return (
              <BrowserRouter>
                <React.Suspense fallback={myLoading()}>
                  <Switch>
                    <Route exact path="/thankyou/:referenceNumber/:type" name="THANK YOU" render={props => <ThankYou {...props} />} />
                    <Route exact path="/Registration/VerifyCustomer/:customerID/:key/:otp" name="VERIFY CUSTOMER" render={props => <VerifyCustomer {...props} />} />
                    <Route exact path="/welcome/:active_view/:orderNumber" name="LANDING PAGE" render={props => <LandingPage {...props} />} />
                    <Route exact path="/welcome/:active_view" name="LANDING PAGE" render={props => <LandingPage {...props} />} />
                    <Route exact path="/welcome" name="LANDING PAGE" render={props => <LandingPage {...props} />} />
                    <Route exact path="/checkout/:code" name="CHECKOUT PAGE" render={props => <CheckoutPage {...props} />} />
                    <Route exact path="/checkout" name="CHECKOUT PAGE" render={props => <CheckoutPage {...props} />} />
                    <Route exact path="/nexusRedemption" name="NEXUS REDEMPTION PAGE" render={props => <NexusRedemptioPage {...props} />} />
                    <Route exact path="/PaymentGateway" name="Payment Gateway" render={props => <PaymentGateway {...props} />} />
                    <Route exact path="/PaymentPageRedirectingHandler" name="Payment Page Redirecting Handler" render={props => <PaymentPageRedirectingHandler {...props} />} />
                    <Route exact path="/trackyourorder/:ordnum/:authfield" name="TRACK YOUR ORDER" render={props => <TrackYourOrderView {...props} />} />
                    <Route exact path="/deliveryInfo" name="DELIVERY INFO" render={props => <DeliveryInfo {...props} />} />
                    <Route exact path="/mobdeliveryInfo" name="DELIVERY INFO" render={props => <DeliveryInfo {...props} />} />
                    <Route exact path="/storeLocatorInfor" name="STORE LOCATOR" render={props => <StoreLocator {...props} />} />
                    <Route exact path="/mobstorelocator" name="STORE LOCATOR" render={props => <StoreLocator {...props} />} />
                    {/* <Route exact path="/aboutus" name="ABOUT US PAGE" render={props => <AboutUs {...props} />} /> */}
                    <Route exact path="/trackyourorder/:ordnum" name="TRACK YOUR ORDER" render={props => <TrackYourOrderView {...props} />} />
                    <Route exact path="/mobfaq/:question" name="FAQ" render={props => <FAQPage {...props} />} />
                    <Route exact path="/mobfaq" name="FAQ" render={props => <FAQPage {...props} />} />
                    <Route exact path="/mobaboutus" name="ABOUT US" render={props => <AboutUs {...props} />} />
                    <Route exact path="/mobtc/:focus" name="TERMS AND CONDITIONS" render={props => <TermsAndConditions {...props} />} />
                    <Route exact path="/mobtc" name="TERMS AND CONDITIONS" render={props => <TermsAndConditions {...props} />} />
                    <Route exact path="/mobnexustc" name="NEXUS TERMS AND CONDITIONS" render={props => <NexusTC {...props} />} />
                    <Route exact path="/mobprivacypolicy" name="PRIVACY POLICY" render={props => <PrivacyPolicy {...props} />} />
                    <Route exact path="/mymedtracker/:ordernumber/:email" name="MY MED ORDER TRACKER" render={props => <MyMedOrderTracker {...props} />} />
                    <Route exact path="/showcase/:promocode" name="SHOWCASE" render={props => <ShowcaseExternal {...props} />} />
                    <Route exact path="/showcase/items/:promocode/:type/:typeid/:popupcode" name="BRAND PAGE" render={props => <BrandPageExternal {...props} />} />
                    <Route exact path="/showcase/items/:promocode/:type/:typeid" name="BRAND PAGE" render={props => <BrandPageExternal {...props} />} />
                    <Route exact path="/showcase/items/:promocode/:popupcode" name="BRAND PAGE" render={props => <BrandPageExternal {...props} />} />
                    <Route exact path="/showcase/items/:promocode" name="BRAND PAGE" render={props => <BrandPageExternal {...props} />} />
                    <Route exact path="/promotions" name="Promotions" render={props => <AllPromotiomnsExternal {...props} />} />
                    <Route exact path="/bankPromotions" name="Promotions" render={props => <BankPromotionExternal {...props} />} />
                    <Route exact path="/exclusivePromotions/:deal" name="Promotions" render={props => <ExclusivePromotionExternal {...props} />} />
                    <Route exact path="/productExt" name="PRODUCT LISTING" render={props => <ProductListingExternal {...props} />} />
                    <Route exact path="/helpwizard" name="HELP WIZARD" render={props => <HelpWizard {...props} />} />
                    <Route exact path="/ebill/:customercode" name="E-BILL SUBSCRIBTION" render={props => <EBill {...props} />} /> {/*Old ebill URL version*/}
                    <Route exact path="/ebill/" name="E-BILL SUBSCRIBTION" render={props => <EBill {...props} />} />
                    <Route exact path="/feedback/:type/:orderNo" name="Customer Feedback" render={props => <Feedback {...props} />} />
                    <Route exact path="/keells-exclusives-2021" name="Keells Exclusives" render={props => <DirectImportsPage {...props} />} />
                    <Route exact path="/cardpromo/:code" name="Card Promotions" render={props => <CardPromo {...props} />} />
                    <Route exact path="/onlinestore/:storecode" name="Online Store External" render={props => <OnlineStoreExternal {...props} />} />
                    <Route exact path="/products" name="PRODUCT LISTING" render={props => <ProductListing {...props} />} />
                    {/* <Route exact path="/personalizedpromotion/:channelid/:data" name="Personalised Promotions" render={props => <PersonalizedPromotion {...props} />} />
                    <Route exact path="/personalizedpromotion/admin/:channelid/:batchid/:customerid" name="Personalised Promotions Admin" render={props => <PersonalizedPromotion {...props} />} />
                    <Route exact path="/churnpromotion/cwl/:channelid/:data" name="Churn With Link Promotions" render={props => <ChurnWithLinkPromotion {...props} />} />
                    <Route exact path="/churnpromotion/admin/cwl/:channelid/:batchid/:customerid" name="Churn With Link Promotions Admin" render={props => <ChurnWithLinkPromotion {...props} />} />
                    <Route exact path="/personalizedpromotion/:channelid" name="Personalised Promotions Customer Portal" render={props => <PPCustomerPortal {...props} />} /> */}
                    <Route exact path="/mykeellsdeals/:channelid/:data" name="Personalised Promotions" render={props => <PersonalizedPromotion {...props} />} />
                    <Route exact path="/mykeellsdeals/admin/:channelid/:batchid/:customerid" name="Personalised Promotions Admin" render={props => <PersonalizedPromotion {...props} />} />
                    <Route exact path="/exclusiveoffers/cwl/:channelid/:data" name="Churn With Link Promotions" render={props => <ChurnWithLinkPromotion {...props} />} />
                    <Route exact path="/exclusiveoffers/admin/cwl/:channelid/:batchid/:customerid" name="Churn With Link Promotions Admin" render={props => <ChurnWithLinkPromotion {...props} />} />
                    <Route exact path="/mykeellsdeals/:channelid" name="Personalised Promotions Customer Portal" render={props => <PPCustomerPortal {...props} />} />
                    <Route exact path="/ppromotion/msg/:msgcode" name="Personalised Promotions Msg" render={props => <PPCustomerPortalMsg {...props} />} />
                    {/* <Route exact path="/donation" name="Donation" render={props => <DonationExternal {...props} />} /> */}
                    {/* <Route exact path="/donation" name="Mealcard" render={props => <MealCardExternal {...props} />} /> */}
                    <Route exact path="/receiptPage" name="Receipt Page" render={props => <ReceiptPage {...props} />} />
                    <Route exact path="/amexReturnPage:code" name="AMEX Page" render={props => <AMEXReturnPage {...props} />} />
                    {/* <Route exact path="/utilitypaymentscompleted/:code" name="Utilitypayments Completed" render={props => <UtilityPaymentView {...props} />} /> */}
                    <Route exact path="/account/address/:code" name="Geo Location" render={props => <GeoLocationExternal {...props} />} />
                    <Route path="/tabloid" name="tabloid view" render={props => <Tabloid {...props} />} />
                    <Route path="/tabloidunsubscription" name="subscription view" render={props => <UnSubriptionPage {...props} />} />
                    <Route path="/tabloidsubscription" name="subscription view" render={props => <SubriptionPage {...props} />} />
                    <Route path="/deals" name="Keells Reach Deals" render={props => <WebExtension {...props} />} />
                    <Route path="/promotionPDF" name="Promotion PDF" render={props => <PromotionPDF {...props} />} />
                    <Route path="/ProductDetail/" name="ProductDetail" render={props => <ProductDetailExternal {...props} />} />
                    <Route path="/ContactUsExternal" name="Contact Us" render={props => <ContactUsExternal {...props} />} />
                    <Route path="/MillionaireCustomerFAQ" name="Millionaire PDF" render={props => <MillionairePDF {...props} />} />
                    <Route path="/" name="DEFAULT LAYOUT" render={props => <DefaultPage {...props} />} />
                  </Switch>
                </React.Suspense>
              </BrowserRouter>
            );
          } else {
            return (
              <BrowserRouter>
                <React.Suspense fallback={myLoading()}>
                  <Switch>
                    {PromotionEnvironment == "KEELLSKAFE" ?
                      <Route exact path="/" name="KEELLS KAFE" render={props => <PDFViewer pdfLink={'https://essstr.blob.core.windows.net/tabloid/Iconic%20Menu%20Book.pdf'} />} />
                      : PromotionEnvironment == "KEELLSMENU" ?
                        <Route exact path="/" name="KEELLS MENU" render={props => <PDFViewer pdfLink={'https://essstr.blob.core.windows.net/pdf/Hot%20Food%20Menu%20Book.pdf'} />} />
                        : PromotionEnvironment == "KEELLSABOUTUS" ?
                          <Route exact path="/" name="KEELLS ABOUT US" render={props => <PDFViewer pdfLink={'https://essstr.blob.core.windows.net/pdf/CompanyProfile.pdf'} />} />
                          : PromotionEnvironment == "KEELLSPRICELIST" ?
                            <Route exact path="/" name="KEELLS PRICE LIST" render={props => <PDFViewer pdfLink={'https://essstr.blob.core.windows.net/pdf/PriceList.pdf'} />} />
                            : PromotionEnvironment == "KEELLSSTORES" ?
                              <Route exact path="/" name="KEELLS STORES" render={props => <PDFViewer pdfLink={'https://essstr.blob.core.windows.net/pdf/ItemAvailableStores.pdf'} />} />
                              : PromotionEnvironment == "TABLOID" ?
                                <Route exact path="/" name="TABLOID" render={props => <Tabloid {...props} />} />
                                : <Route exact path="/" name="TURN PAGE" render={props => <TurnPage {...props} />} />
                    }
                  </Switch>
                </React.Suspense>
              </BrowserRouter>
            );
          }
        }
        }
      </CacheBuster >
    )
  }

}

export default App;
