export const utilityCartReducer = (state = [], action) => {
    switch (action.type) {
        case 'ADD_UTILITY_TO_CART':
            return state.concat(action.payload)
        case 'UPDATE_UTILITY_CART':
            return action.payload
        case 'REMOVE_UTILITY_FROM_CART':
            return action.payload
        case 'RESET_UTILITY_CART':
            return []
        default:
            return state
    }
};