const initialState = {
    isActive: false,
    message: "",
    cssClass: "info",
    triggered: false
};

export const insideNotificationStripeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_INSIDE_NOTIFICATION_STRIPE':
            return {
                isActive: action.payload.isActive,
                message: action.payload.message,
                cssClass: action.payload.cssClass,
                triggered: action.payload.triggered
            };
        case 'RESET_INSIDE_NOTIFICATION_STRIPE':
            return initialState;
        default:
            return state;
    }
};

export const outsideNotificationStripeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_OUTSIDE_NOTIFICATION_STRIPE':
            return {
                isActive: action.payload.isActive,
                message: action.payload.message,
                cssClass: action.payload.cssClass,
                triggered: action.payload.triggered
            };
        case 'RESET_OUTSIDE_NOTIFICATION_STRIPE':
            return initialState;
        default:
            return state;
    }
};