const initialState = {
    token: ""
};

export const queueManagerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_QUEUE_MANAGER_TOKEN':
            return {
                token: action.payload
            };
        case 'RESET_QUEUE_MANAGER':
            return initialState;
        default:
            return state;
    }
};