const initialState = {
    show: false
};

export const preferenceModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_PREFERENCE_MODAL':
            return {
                show: action.payload.show,
            };
        case 'RESET_PREFERENCE_MODAL':
            return initialState;
        default:
            return state;
    }
};