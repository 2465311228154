const initialState = {
    notes: "",
    deliveryDate: "",
    card: ""
};

export const checkoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_CHECKOUT_NOTES':
            return {
                notes: action.payload,

                deliveryDate: state.deliveryDate,
                card: state.card
            };
        case 'UPDATE_CHECKOUT_DELDATE':
            return {
                deliveryDate: action.payload,

                notes: state.notes,
                card: state.card
            };
        case 'UPDATE_CHECKOUT_CARD':
            return {
                card: action.payload,

                notes: state.notes,
                deliveryDate: state.deliveryDate
            };
        case 'RESET_CHECKOUT':
            return initialState;
        default:
            return state;
    }
}