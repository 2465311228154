export const promotionItemsReducer = (state = [], action) => {
    switch (action.type) {
        case 'ADD_PROMOTION_ITEMS':
            if (state.length > 0) {
                let temp = [];
                action.payload.map(pay => {
                    let existArr = [];
                    existArr = state.filter(elm => elm.promotionDetailID === pay.promotionDetailID && elm.promotionHeaderID === pay.promotionHeaderID);
                    if (existArr.length === 0) {
                        temp.push(pay);
                    }
                })
                return state.concat(temp);
            } else { return action.payload }
        case 'RESET_PROMOTION_ITEMS':
            return []
        default:
            return state
    }
};