const initialState = {
    show: false,
    imageUrl: ""
};

export const productMagnifierReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_PRODUCT_MAGNIFIER':
            return {
                show: action.payload.show,
                imageUrl: action.payload.imageUrl
            };
        case 'RESET_PRODUCT_MAGNIFIER':
            return initialState;
        default:
            return state;
    }
};