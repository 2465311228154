const initialState = {
    isTabScreen: false,
    isMobileLargeScreen: false,
    isMobileSmallScreen: false,
};

export const responsiveReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_RESPONSIVE':
            return {
                isTabScreen: action.payload.isTabScreen,
                isMobileLargeScreen: action.payload.isMobileLargeScreen,
                isMobileSmallScreen: action.payload.isMobileSmallScreen,
            };
        case 'RESET_RESPONSIVE':
            return initialState;
        default:
            return state;
    }
};