const initialState = {
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    nicNo: "",
    addressNo: "",
    street: "",
    city: "",
    isAgreedForCondition: false,
    initialDataLoading: false,
};

export const nexusRegistrationDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_NEXUS_REGISTRATION_DETAIL':
            return {
                title: action.payload.title,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                email: action.payload.email,
                mobileNumber: action.payload.mobileNumber,
                nicNo: action.payload.nicNo,
                addressNo: action.payload.addressNo,
                street: action.payload.street,
                city: action.payload.city,
                isAgreedForCondition: action.payload.isAgreedForCondition,
                initialDataLoading: action.payload.initialDataLoading,
            };
        case 'RESET_NEXUS_REGISTRATION_DETAIL':
            return initialState;
        default:
            return state;
    }
};