import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { DefaulProductImage } from "./environment_config";



class HelmetMeta extends Component {
    render() {
        const path_url = window.location.href
        const fburl = path_url.split('/')
        const twiterurl = fburl[2].replace(/\./g, '_')
        return (
            <>
                <Helmet>
                    {/* Facebook */}
                    <meta property="og:type" content="article" />
                    <meta property="og:url" content={!this.props.val ? "https://www.keellssuper.com/welcome" : path_url} />
                    <meta property="og:title" content={!this.props.val ? "Keells Online" : this.props.title} />
                    <meta property="og:site_name" content={fburl[2]} />
                    <meta property="og:description" content="Keellssuper.com | Sri Lanka's Largest Online Shopping Store" />
                    <meta property="og:image" content={!this.props.val || this.props.val === DefaulProductImage ? "https://essstr.blob.core.windows.net/uiimg/keellslogo.png" : this.props.val} />

                    {/* Twitter */}
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={!this.props.val ? "https://www.keellssuper.com/welcome" : path_url} />
                    <meta property="twitter:title" content={!this.props.val ? "Keells Online" : this.props.title} />
                    <meta property="twitter:site" content={`@${twiterurl}`} />
                    <meta property="twitter:description" content="Keellssuper.com | Sri Lanka's Largest Online Shopping Store" />
                    <meta property="twitter:image" content={!this.props.val || this.props.val === DefaulProductImage ? "https://essstr.blob.core.windows.net/uiimg/keellslogo.png" : this.props.val} />
                </Helmet>
            </>
        );
    }
}

export default HelmetMeta