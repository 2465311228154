const initialState = {
    isShowBrowswStore: false,
};

export const welcomePageTilesReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'UPDATE_IS_SHOW_GUEST_LOGIN_FLAG':
            return {
                isShowBrowswStore: action.payload,
            };
        case 'RESET_WELCOME_PAGE_TILES':
            return initialState;
        default:
            return state;
    }
};