const initialState = {
    OrderNumber: "",
    ContactNumber: "",
    AddressLine1: "",
    AddressLine2: "",
    City: "",
    LocationCode: "",
    MainLocationCode: "",
    PayMode: 'Card Payment',
    PayModeId: 0,
    IsCardPaymentRetry: false,
    ItemList: [],
    TotalAmount: 0,
    PickupDateTime: null,
    DeliveryType: 0,
    RefundOptionID: 0,
    IsDefaultRefundOption: false,
    CustomerID: 0,
    SuburbID: 0,
    Comment: "",
    RecipientName: "",
    OrderFreeIssuePromotionList: [],
    NexusTransactionReference: "",
    NexusReference: "",
    RedeemedNexusAmount: 0,
    IsQuarantine: false,
    IsSaveCard: false,
    CustomerPaymentTokenID: 0,
    LiquorConsentConfirmed: false,
    IsJKOA: false,
    CurrencyType: "LKR",
    CurrencyRate: 0,
    IsForeignCurrencyOrder: false,
    MissingItemsRemark: '',
    PaymentMethod: '',
    IsExpressDelivery: false,
    ShippingDetailsId: 0,
    DeliveryCharge: 0,

    NewPromoCodeID: 0,
    NewPromoCodeRedeemedValue: 0,
    NewPromoCodePromotionHeaderID: 0,
};

export const orderDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_ORDER_DATA':
            return {
                NexusTransactionReference: state.NexusTransactionReference,
                NexusReference: state.NexusReference,
                RedeemedNexusAmount: state.RedeemedNexusAmount,
                OrderNumber: action.payload.OrderNumber,
                ContactNumber: action.payload.ContactNumber,
                AddressLine1: action.payload.AddressLine1,
                AddressLine2: action.payload.AddressLine2,
                City: action.payload.City,
                LocationCode: action.payload.LocationCode,
                MainLocationCode: action.payload.MainLocationCode,
                PayMode: action.payload.PayMode,
                PayModeId: action.payload.PayModeId,
                IsCardPaymentRetry: action.payload.IsCardPaymentRetry,
                ItemList: action.payload.ItemList,
                TotalAmount: action.payload.TotalAmount,
                PickupDateTime: action.payload.PickupDateTime,
                DeliveryType: action.payload.DeliveryType,
                RefundOptionID: action.payload.RefundOptionID,
                IsDefaultRefundOption: action.payload.IsDefaultRefundOption,
                CustomerID: action.payload.CustomerID,
                SuburbID: action.payload.SuburbID,
                Comment: action.payload.Comment,
                RecipientName: action.payload.RecipientName,
                OrderFreeIssuePromotionList: action.payload.OrderFreeIssuePromotionList,
                IsQuarantine: action.payload.IsQuarantine,
                IsSaveCard: action.payload.IsSaveCard,
                CustomerPaymentTokenID: action.payload.CustomerPaymentTokenID,
                LiquorConsentConfirmed: action.payload.LiquorConsentConfirmed,
                IsJKOA: action.payload.IsJKOA,
                CurrencyType: action.payload.CurrencyType,
                CurrencyRate: action.payload.CurrencyRate,
                IsForeignCurrencyOrder: action.payload.IsForeignCurrencyOrder,
                MissingItemsRemark: action.payload.MissingItemsRemark,
                PaymentMethod: action.payload.PaymentMethod,
                IsExpressDelivery: action.payload.IsExpressDelivery,
            };
        case 'RESET_ORDER_DATA':
            return initialState;
        default:
            return state;
    }
};