const initialState = {
     promoCodeID: null 
    , promoCodeFormat: ""
    , promoCodeDiscountedAmount: 0.00
    , itemsFinalTotalAmount: 0.00
    , promoCodeFormatValidationMessage: ""
    , promoCodeName: ""
    , minBillValue: 0
    , maxBillValue: 0
    , promoCodeMaxRedeemValue: 0

    , promotionHeaderID: 0
    , promoCodePromotionDetails: {
         promotionCode: ""
        , promoTypeID: 0
        , promoSubTypeID: 0
        , merchantId: ""
        , discountType: ""
        , discountPercentage: 0
        , discountValue: 0
    }
};

export const promoCodeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'promoCodes/redeemed':
            return {
                promoCodeID: action.payload.promoCodeID
                , promoCodeFormat: action.payload.promoCodeFormat
                , promoCodeDiscountedAmount: action.payload.promoCodeDiscountedAmount
                , itemsFinalTotalAmount: action.payload.itemsFinalTotalAmount
                , promoCodeFormatValidationMessage: action.payload.promoCodeFormatValidationMessage
                , promoCodeName: action.payload.promoCodeName
                , minBillValue: action.payload.minBillValue
                , maxBillValue: action.payload.maxBillValue
                , promoCodeMaxRedeemValue: action.payload.promoCodeMaxRedeemValue

                , promotionHeaderID: action.payload.promotionHeaderID
                , promoCodePromotionDetails: {
                    promotionCode: action.payload.promoCodePromotionDetails.promotionCode
                    , promoTypeID: action.payload.promoCodePromotionDetails.promoTypeID
                    , promoSubTypeID: action.payload.promoCodePromotionDetails.promoSubTypeID
                    , merchantId: action.payload.promoCodePromotionDetails.merchantId
                    , discountType: action.payload.promoCodePromotionDetails.discountType
                    , discountPercentage: action.payload.promoCodePromotionDetails.discountPercentage
                    , discountValue: action.payload.promoCodePromotionDetails.discountValue
                }
            };
        case 'promoCodes/reset':
            return initialState;
        default:
            return state;
    }
};