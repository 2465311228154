const initialState = {
    utilityPaymentCategories: [],
    utilityPaymentModes: [],
    utilityPaymentModeDetails: []
};

export const utilityDataCollectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_UTILITY_DATA_COLLECTION':
            return {
                utilityPaymentCategories: action.payload.utilityPaymentCategories,
                utilityPaymentModes: action.payload.utilityPaymentModes,
                utilityPaymentModeDetails: action.payload.utilityPaymentModeDetails,
            };
        case 'RESET_UTILITY_DATA_COLLECTION':
            return initialState;
        default:
            return state;
    }
};
