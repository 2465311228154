import React, { Component } from 'react';

class PDFViewer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const pdfLink = this.props && this.props.pdfLink ? this.props.pdfLink : '';
        const googleDocsViewerUrl = `https://docs.google.com/gview?url=${pdfLink}&embedded=true`;

        return (
            <div>
                <div style={{ width: '100%', maxWidth: '100%', height: '100vh' }}>
                    <iframe
                        src={googleDocsViewerUrl}
                        style={{
                            width: '100%',
                            height: '100%',
                            border: '8px solid white',
                            display: 'block',
                            backgroundColor: '#4a4a4a'
                        }}
                        frameBorder="0"
                    >
                    </iframe>
                </div>
            </div>
        );
    }
}

export default PDFViewer