const initialState = {
    rcs: ""
};

export const algonomyReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_ALGONOMY_RCS':
            return {
                rcs: action.payload.rcs
            };
        case 'RESET_ALGONOMY_DATA':
            return initialState;
        default:
            return state;
    }
};