const initialState = {
    isLegalAge: false,
    attemptedVerification: false,
};

export const ageVerificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_AGEVERIFICATION':
            return {
                isLegalAge: action.payload.isLegalAge,
                attemptedVerification: action.payload.attemptedVerification,
            };
        case 'RESET_AGEVERIFICATION':
            return initialState;
        default:
            return state;
    }
};